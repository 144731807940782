import React from 'react'
import { Link } from 'gatsby'
import arrowStyles from './arrow.module.scss'
import PaintHouse from './paint-house.svg'
import ArrowR from './arrow.svg'

export default function Arrow({ to }) {
  return (
    <Link to={to} className={arrowStyles.arrow}>
      <div className={arrowStyles.row}>
        <div className={arrowStyles.textLink}>Enter Artist Studio</div>
        <ArrowR className={arrowStyles.arrowButton} />
      </div>
      <PaintHouse className={arrowStyles.paintHouse} />
    </Link>
  )
}
