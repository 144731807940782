import React from 'react'
import Layout from '../../components/layout/layout.component'
import studioStyles from './studio.module.scss'
import { graphql, useStaticQuery } from 'gatsby'
import Arrow from '../../components/arrow/arrow.component'
import BackgroundImage from 'gatsby-background-image'

export default function StudioPage() {
  const data = useStaticQuery(graphql`
    query {
      file(relativeDirectory: { eq: "studio" }, base: { eq: "f1.jpg" }) {
        childImageSharp {
          fixed(width: 1200, quality: 60) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `)
  return (
    <Layout hasBackground showFooter isMenuWhite>
      <div className={studioStyles.container}>
        <div className={studioStyles.blackBackground} />
        <BackgroundImage
          Tag="section"
          className={studioStyles.studio}
          fixed={data.file.childImageSharp.fixed}
          style={{
            position: 'absolute',
            width: '100vw',
            height: '100vh',
          }}
          fadeIn
        />
        <Arrow to="/studio/workshop" />
      </div>
    </Layout>
  )
}
